<template>
  <div class="mt-60 width-full">
    <main>
      <details :class="{ disabled: !authorizedUser }" v-if="authorizedUser">
        <summary
          class="pointer pb-10 pt-15"
          :class="{
            'iop-drk-blue-100': authorizedUser,
            'gray-600': !authorizedUser,
          }"
        >
          <span class="semibold font-white pl-10">Create a QR Code</span>
        </summary>
        <div class="create-on-load" v-show="createSuccess">
          <div class="inline-text-field-container pl-30 pr-30 pt-20">
            <div class="flex">
              <div
                class="
                  pt-20
                  pr-20
                  pl-20
                  font-iop-blue
                  gray-250
                  radius-4-top-left
                "
                style="white-space: nowrap"
                v-if="selectedDomain != null"
              >
                https://{{ selectedDomain }}/
              </div>
              <div class="ds-text-field drk mb-0 width-full">
                <input
                  id="qr-url"
                  type="text"
                  v-model="form.qrURL"
                  class="ds-text-field__input radius-4-top-right"
                  autocomplete="off"
                  required
                />
                <label class="ds-floating-label"
                  >Enter the path<span
                    style="
                      color: #ff0000;
                      position: absolute;
                      top: -1px;
                      font-size: 18px;
                    "
                    >*</span
                  ></label
                >
                <div class="field-line-ripple"></div>
              </div>
              <div class="field-line-ripple"></div>
            </div>
            <div
              class="font-red mb-0 m-negative15 small"
              v-if="inputFieldsEmpty"
            >
              <p>{{ inputFieldsEmpty }}</p>
            </div>
            <div
              class="font-red mb-0 m-negative15 small mt-0"
              v-if="originalURLEmpty && !inputFieldsEmpty"
            >
              <p>{{ originalURLEmpty }}</p>
            </div>
            <div
              class="font-red mb-0 m-negative15 small mt-0"
              v-if="invalidUrl && !originalURLEmpty && !inputFieldsEmpty"
            >
              <p>{{ invalidUrl }}</p>
            </div>
            <div class="small mt-5">
              <span v-if="form.qrURL">
                https://{{ selectedDomain }}/{{ form.qrURL }}</span
              >
            </div>
            <p class="small mt-5">
              Must be all lower-case with no special characters
            </p>

            <div class="flex flex-row mr-50">
              <div class="file-upload border mr-20">
                <!-- This is drag and drop file upload -->
                <div
                  v-if="!image"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <div class="sample-img mt-10">
                    <img
                      src="../../../assets/data/default-logo.png"
                      class="qr-image"
                      style="height: 150px; width: 150px"
                    />
                  </div>
                  <div class="pb-10 gray-250">
                    <div
                      class="text-center pb-10"
                      @dragover="dragover"
                      @dragleave="dragleave"
                      @drop="drop"
                    >
                      <input
                        type="file"
                        multiple
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        ref="file"
                        accept="image/x-png,image/jpg,image/jpeg"
                      />
                      <label
                        for="assetsFieldHandle"
                        style="display: inline-block; cursor: pointer"
                      >
                        <div>
                          <p class="bold mb-0 font-20">
                            Drag and Drop Your Logo Image
                          </p>
                          or
                          <span class="underline font-iop-drk-blue semibold"
                            >browse</span
                          >
                          to choose a file to upload <br />
                          <i>(.png, .jpg, .jpeg)</i>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="sample-img mt-10">
                    <img
                      v-bind:src="this.generateImage"
                      class="qr-image"
                      style="width: 150px"
                    />
                  </div>
                  <div
                    class="gray-250"
                    v-for="(file, key) in filelist"
                    v-bind:key="key"
                  >
                    <!-- <ul v-if="this.filelist.length" v-cloak> -->
                    <!-- <li
                        class="text-sm"
                        v-for="(file, key) in filelist"
                        v-bind:key="key"
                      > -->
                    <div class="flex flex-col align-center pb-10 gray-250">
                      <div class="bold pt-10 pb-10 text-center">
                        {{ file.name }}
                      </div>
                      <div class="text-center">
                        <button
                          class="iop-drk-blue-100 radius-20 w300 pointer"
                          type="button"
                          @click="remove(filelist.indexOf(file))"
                          title="Remove file"
                        >
                          <div
                            class="
                              iop-drk-blue-100
                              font-white
                              semibold
                              btn-text
                              source-sans-pro
                            "
                          >
                            Remove image
                          </div>
                        </button>
                      </div>
                    </div>
                    <!-- </li>
                    </ul> -->
                  </div>
                </div>
                <!-- This is drag and drop file upload -->
              </div>
              <div class="flex justify-right">
                <div class="pt-0">
                  <div class="ds-text-field drk mr-10 mb-0">
                    <input
                      id="title"
                      type="text"
                      v-model="form.title"
                      class="ds-text-field__input radius-4-top min-w400"
                      autocomplete="off"
                      required
                    />
                    <label for="title" class="ds-floating-label"
                      >QR Code Title<span
                        style="
                          color: #ff0000;
                          position: absolute;
                          top: -1px;
                          font-size: 18px;
                        "
                        >*</span
                      ></label
                    >
                    <div class="field-line-ripple"></div>
                  </div>
                  <div
                    class="font-red mb-0 m-negative15 small"
                    v-if="inputFieldsEmpty"
                  >
                    <p>{{ inputFieldsEmpty }}</p>
                  </div>
                  <div
                    class="font-red mb-0 m-negative15 small mt-0"
                    v-if="titleEmpty && !inputFieldsEmpty"
                  >
                    <p>{{ titleEmpty }}</p>
                  </div>
                  <div class="ds-text-field drk mt-10 mr-10 mb-20 h80">
                    <input
                      id="description"
                      type="text"
                      v-model="form.description"
                      class="ds-text-field__input radius-4-top-right min-w400"
                      autocomplete="off"
                      required
                    />
                    <!--                     <textarea
                      id="description"
                      v-model="form.description"
                      cols="50"
                      class="ds-text-field drk border-none h100 source-sans"
                    ></textarea> -->
                    <label class="ds-floating-label">QR Code Description</label>
                    <div class="field-line-ripple"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="pl-30">
              <div
                class="font-red mb-0 m-negative45 small mt-0"
                v-if="fileError"
              >
                <p>{{ createErrorMessage }}</p>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="pl-30">
              <p class="small mt-15">
                If you don’t wish to customize your QR code with your agency’s
                logo, it will default to the IOP logo.
              </p>
            </div>
            <div class="pb-30 pl-80">
              <button
                id="button-js--primary"
                class="blue-200 radius-20 w300 pointer"
                @click="submitUrl()"
              >
                <div
                  class="font-iop-drk-blue semibold btn-text source-sans-pro"
                >
                  Create QR Code
                </div>
              </button>
            </div>
          </div>
        </div>
        <div
          class="inline-text-field-container pl-30 pr-30 pt-20 pb-30"
          v-show="isShow"
          style="background: #e2ebf2"
        >
          <div class="flex flex-wrap pl-60 pt-40 align-center">
            <div class="pt-10 pr-10 wpc20 circle dot white">
              <div class="checkmark semibold font-iop-blue"></div>
            </div>
            <div class="wpc80 pl-50">
              <h2
                class="
                  pt-10
                  font-25 font-iop-drk-blue
                  semibold
                  btn-text
                  source-sans-pro
                "
              >
                Success!
              </h2>
              <p
                class="semibold btn-text source-sans-pro"
                style="white-space: pre-line"
              >
                Successfully generated QRCode for the url <br />{{
                  createSuccessMessage
                }}
              </p>
            </div>
          </div>
          <div class="flex justify-right mt-10">
            <button
              id="button-js--primary"
              class="iop-drk-blue-100 radius-20 w200 pointer mr-20"
              @click="setShow()"
            >
              <div class="font-white bold btn-text source-sans-pro">Okay</div>
            </button>
          </div>
        </div>
      </details>
      <p v-else style="margin-bottom: 20px">
        You don't have access to create QR Codes. Ask someone from your agency
        with L2/Content Approver permissions for help.
      </p>
      <QRCodeList
        :selectedDomain="selectedDomain"
        :fileredSites="fileredSites"
        :authorizedUser="authorizedUser"
        v-if="selectedDomain != null"
        ref="child"
      />
    </main>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import QRCodeStyling from "qr-code-styling";
import QRCodeList from "../../../components/_devportal/QrCode/QRCodeList.vue";
import { AuthConfig } from "../../../auth/auth.config.js";
export default {
  props: ["selectedDomain", "fileredSites", "authorizedUser"],
  delimiters: ["${", "}"],
  components: {
    QRCodeList,
  },
  data: () => ({
    bus: new Vue(),
    ref: "",
    siteList: [],
    filelist: [], // Store our uploaded files
    form: {
      qrURL: "",
      title: "",
      description: "",
    },
    createSuccess: true,
    createSuccessMessage: "",
    createError: false,
    createErrorMessage: "",
    originalURLEmpty: "",
    titleEmpty: "",
    inputFieldsEmpty: "",
    isShow: false,
    keyExists: false,
    showQrImg: false,
    image: "",
    fileError: false,
    checkHttpUrl: false,
    invalidUrl: "",
    generateImage: "",
  }),
  mounted() {
    this.ref = this.$refs;
  },
  created() {
    //console.log("Domain", this.selectedDomain);
  },
  methods: {
    setShow: function () {
      this.isShow = false;
      this.createSuccess = true;
      this.createError = false;
      this.fileError = false;
      this.form.qrURL = "";
      this.form.title = "";
      this.form.description = "";
      this.createErrorMessage = "";
      this.image = "";
      this.filelist = [];
      this.inputFieldsEmpty = "";
      this.originalURLEmpty = "";
      this.titleEmpty = "";
      this.fileError = false;
      this.invalidUrl = "";
      this.checkHttpUrl = false;
      this.createList(this.selectedDomain);
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
      //console.log("file list", this.filelist, this.filelist[0].type);
      var files = [...this.$refs.file.files];
      if (!files.length) {
        this.image = "";
        return;
      }
      if (
        this.filelist[0].type === "image/png" ||
        this.filelist[0].type === "image/jpeg" ||
        this.filelist[0].type === "image/jpg"
      ) {
        this.fileError = false;
        this.createErrorMessage = "";
        this.createImage(files[0]);
      } else {
        this.filelist = "";
        this.fileError = true;
        this.createErrorMessage = "Allowed file types are .png, .jpeg, .jpg";
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.image = "";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.image = "";
        return;
      }
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      this.image = image;
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
        this.dummyImage = vm.image;
        //console.log("vm.image", vm.image);
        // show QR prior to submit
        let sampleUrl = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
        this.createQR(sampleUrl, vm.image).then((qrImage) => {
          this.generateImage = qrImage;
        });
      };
      reader.readAsDataURL(file);
    },
    createList(selectedDomain) {
      this.ref.child.getURList(selectedDomain);
    },
    isValidURL(url) {
      //Check if URL is Http or https
      //console.log("url", url);
      var pattern1 = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z0-9\\_.~#+]*)*" + // port and path
          "(\\?[;&a-z\\_.~+#=-]*)?" + // query string
          "(\\[-a-z\\d_]*)?$",
        "i"
      );
      // path extension should not be pdf/jpg/png/gif for long and friendly Urls
      //var pattern2 = new RegExp("^.*\\.(pdf|jpg|jpeg|png|gif)$");
      //  url path should allow the following special characters only
      //var pattern3 = new RegExp("^([a-zA-Z0-9_.~/-]*)$");
      this.checkHttpUrl = !!pattern1.test(url);
      //this.checkPdfUrl = !!pattern2.test(url);
      //this.checkPattern = !!pattern3.test(url);
      //console.log("checkUrl", this.checkHttpUrl);
      if (
        this.checkHttpUrl == true
        //&& this.checkPdfUrl == false
        //&& this.checkPattern == true
      ) {
        return true;
      }
      return false;
    },
    submitUrl: function () {
      if (this.form.qrURL && this.form.title) {
        // read image source if user uploads an image
        var precedeFriendlyWithSlash = this.form.qrURL.startsWith("/");
        // Precede URl with forward slash if forward slash is not entered by the user
        if (precedeFriendlyWithSlash === false) {
          this.form.qrURL = "/" + this.form.qrURL;
        }
        let customImage = this.image ? this.image : "";
        var reqUrl =
          "https://" + this.selectedDomain + this.form.qrURL.toLowerCase();
        var checkValidUrl = this.isValidURL(reqUrl.trim());
        if (checkValidUrl === true) {
          //this.generateQR(reqUrl);
          this.createQR(reqUrl, customImage)
            .then((qrImage) => {
              this.postImageToApi(
                qrImage,
                reqUrl,
                this.form.title,
                this.form.description
              );
            })
            .then(() => {
              this.createError = false;
              this.createSuccess = false;
              this.isShow = true;
              this.createSuccessMessage = " " + reqUrl;
            })
            .catch((err) => {
              this.createError = true;
              this.createErrorMessage = "POST Error: Something went wrong!!";
              console.log(err);
            });
        } else {
          this.createError = true;
          this.invalidUrl =
            "Sorry, the URL cannot contain special characters like !@#$%^&*(). Try again, choosing a different URL.";
          this.originalURLEmpty = "";
          this.titleEmpty = "";
          this.inputFieldsEmpty = "";
        }
      } else if (this.form.title == "" && this.form.qrURL == "") {
        this.createError = true;
        this.inputFieldsEmpty = "Oops! Fill in both the URL and the title. ";
        this.originalURLEmpty = "";
        this.titleEmpty = "";
      } else if (this.form.qrURL == "") {
        //this.inputFieldsEmpty = "Oops!! Fill in the data";
        this.createError = true;
        this.inputFieldsEmpty = "";
        this.titleEmpty = "";
        this.originalURLEmpty = "QR URL cannot be empty!!";
      } else if (this.form.title == "") {
        this.createError = true;
        this.originalURLEmpty = "";
        this.titleEmpty = "QR Title cannot be empty ";
        this.inputFieldsEmpty = "";
      }
    },
    createQR: async function (reqUrl, customImage) {
      let qrCode;
      if (
        customImage !== "" &&
        customImage !== "undefined" &&
        customImage !== null
      ) {
        //console.log("custom", reqUrl, customImage);
        //imgDim = { width: 115, height: 70 }; //logo dimension
        qrCode = await this.createCanvasImage(reqUrl, customImage);
        return qrCode;
      } else {
        //console.log("default", reqUrl, customImage);
        //imgDim = { width: 50, height: 34 }; //logo dimension
        var logo = require("../../../assets/images/icons/iop-logo-whiteBG.png");
        qrCode = await this.createCanvasImage(reqUrl, logo);
        return qrCode;
      }
    },
    createCanvasImage: async function (dataForQRcode, center_image) {
      const qrCode = new QRCodeStyling({
        width: 700,
        height: 700,
        type: "png",
        data: dataForQRcode,
        image: center_image,
        dotsOptions: {
          color: "#000",
          type: "rounded",
        },
        backgroundOptions: {
          // color: "#e9ebee",
          color: "#FFFFFF",
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 20,
          saveAsBlob: false,
        },
      });
      let rawImage = qrCode.getRawData("png").then((buffer) => {
        // console.log("buffer", buffer);
        const convertBlobToBase64 = async (blob) => {
          // blob data
          let a = await this.blobToBase64(blob);
          return a;
        };
        let b = convertBlobToBase64(buffer);
        return b;
      });
      return rawImage;
    },
    blobToBase64: async function (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    postImageToApi: async function (qrImage, reqUrl, title, description) {
      var domainReq = this.selectedDomain;
      let imageUrl = reqUrl;
      let idToken = sessionStorage.getItem("userIDtoken");
      let tokenExpired = sessionStorage.getItem("tokenExpiresIn");
      let apiPath = AuthConfig.getCurrentAuthConfig().apiGateWay;
      if (Date.now() >= tokenExpired * 1000) {
        let ohidURL = sessionStorage.getItem("ohidUrl");
        window.open(ohidURL + "/pkmslogout", "_self").focus();
      } else {
        let config = {
          headers: {
            Authorization: idToken,
          },
        };
        axios
          .post(apiPath + "/devportal-api/" + domainReq, {
            image: qrImage,
            url: imageUrl,
            title: title,
            description: description,
            createdBy: "Rama.Rachakonda",
          }, config)
          .then(function (response) {
            //console.log("Post Api response:", response);
            return response;
          })
          .catch(function (error) {
            console.log(error);
            return error;
          });
      }
    },
  },
  watch: {
    selectedDomain: function () {
      this.createError = false;
      this.createErrorMessage = "";
      this.form.qrURL = "";
      this.form.title = "";
      this.form.description = "";
      this.image = "";
      this.inputFieldsEmpty = "";
      this.originalURLEmpty = "";
      this.titleEmpty = "";
      this.fileError = false;
      this.invalidUrl = "";
      this.checkHttpUrl = false;
    },
  },
};
</script>
<style scoped>
.file-upload {
  background-color: #ffffff;
  /* border: 2px grey; */
  border-style: dashed;
  min-width: 50%;
}
.uploaded-img {
  width: 50%;
}
.sample-img {
  text-align: center;
}
.dev-portal details[open] {
  background: white !important;
}
[v-cloak] {
  display: none;
}
</style>
