<template>
  <div class="flex">
    <div class="sidebar-container">
      <div class="menu">
        <ul class="menu-items">
          <li class="menu-title current">tools</li>
          <li class="" @click="handleCDNClick">CDN Cache Refresh</li>
          <li class="" @click="handleFriendlyURLClick">Friendly URLs</li>
          <li class="active">QR Codes</li>
        </ul>
      </div>
    </div>

    <!-- SPINNER OVERLAY -->
        <div class="overlay-spinner" v-if="spinnerOverlay">
      <svg class="spinner" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
    </div>
    <!-- SPINNER OVERLAY -->

    <div class="page-container width-full" v-else>
      <div class="subpage-content">
        <div class="content-container p-0 w1000 mlr-9p">
          <section>
            <div class="content-band mt-60">
              <h2 class="semibold font-iop-drk-blue">Create QR Codes</h2>
              <p>
                QR codes are a quick and easy way to direct users to your webpage. These codes can be used on flyers, newsletters, as well as print and social media campaigns.
              </p>
              <p>
                Do not use these codes for fillable forms or account creation pages because user progress can easily be lost. It is best to link directly to a static/informational page you would like users to see.
              </p>
              <div class="mt-30 gray-250 p-40 rounded-12 flex flex-wrap width-full">
                <div class="wpc30 pr-40 pt-10">
                  <h3 class="semibold font-iop-drk-blue">Select a website</h3>
                </div>
                <div class="wpc70 width-full" v-if="filters != ''">
                  <div class="inline-text-field-container">
                    <div class="select-container chevron mt-10">
                      <select
                        v-model="selectedDomain"
                        class="ds-select-field pt-0"
                        @change="test($event)"
                        style="-webkit-appearance: menulist;"
                      >
                        <option class="option" value="null" disabled selected>
                          Select a website to create QR Codes
                        </option>
                        <option
                          class="option"
                          v-for="item in filters"
                          v-bind:key="item.siteName"
                          :value="item.siteDomain"
                        >
                          {{ item.siteDomain }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="inline-text-field-container wpc70" v-else>
                  <div class="gray-50 p-5 rounded-8 flex flex-wrap">
                    <div class="wpc20 blue-200 dot m-10">
                      <div class="bell-icon semibold font-iop-blue"></div>
                    </div>
                    <div class="wpc80 pl-10 pt-15">
                      There is no existing websites with QR Codes to show. Don't
                      see the website you're looking for? Submit a ticket to IOP
                      for help.
                    </div>
                  </div>
                </div>
                <div class="divider mt-20 mlr-0 max-w1000" v-if="selectedDomain != null">
                  <div>
                    <h4 class="semibold font-iop-blue mt-20">
                      {{ selectedDomain }}
                    </h4>
                  </div>
                </div>
                <CreateQRCode
                  :selectedDomain="selectedDomain"
                  :fileredSites="filters"
                  :authorizedUser="authorizedUser"
                  v-if="selectedDomain != null"
                  ref="child"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import CreateQRCode from "../../../components/_devportal/QrCode/CreateQRCode";
import SubFooter from "../../SubFooter";
import logs from "../../../assets/data/fakeAPI.json";
// import TopLevelLeftNav from "./TopLevelLeftNav";
import Vue from "vue";
export default {
  data: () => ({
    spinnerOverlay: true,
    dataArray: [],
    filters: [], // Initialize this to null to load sites list from OHID component
    selectedDomain: null,
    siteList: [],
    bus: new Vue(),
    ref: "",
    authorizedUser: false,
    testData: logs,
  }),
  mounted() {
    this.ref = this.$refs;
    // load this method to retrieve user roles and domain from OHID component
    this.loadUserSiteData();
    //this.loadTestData();
  },
  methods: {
    test(event) {
      console.log(event)
      this.ref.child.createList(event.target.value);
      // details should close when selecting another domain
      document.querySelector("details").removeAttribute("open");
    },
    handleCDNClick: function() {
      this.$router.push("/tools/cdn-cache-refresh");
    },
    handleFriendlyURLClick: function() {
      this.$router.push("/tools/friendly-urls");
    },
    loadUserSiteData: function() {
      //To load the data from session
      var userSiteData = JSON.parse(sessionStorage.getItem("userRoles"));
      if (userSiteData === null) {
        return setTimeout(() => {
          this.loadUserSiteData();
        }, 5000);
      } else {
        this.getUserData(userSiteData);
      }
    },
/*     loadTestData: function() {
      var userSiteData = this.testData;
      this.getUserData(userSiteData);
    }, */
    getUserData: function(userSiteData) {
      /* Load user list from OHID component, if null an fake JSON list is sent as its list data!! 
         This will be updated after testing is complete!!
      */
      this.dataArray = userSiteData;
      this.spinnerOverlay = false;
      if (this.dataArray.length > 0) {
        this.filters = this.dataArray
          .filter(
            (a) =>
              a.siteDomain != "" &&
              (a.userRole == "Manager" ||
                a.userRole == "Reviewer" ||
                a.userRole == "Editor" ||
                a.userRole == "Administrator")
          )
          .sort((a, b) => a.siteDomain.localeCompare(b.siteDomain));
      } else {
        this.filters = [];
      }
    },
  },
  components: {
    // TopLevelLeftNav,
    SubFooter,
    CreateQRCode,
  },
  watch: {
    selectedDomain: function(currentValue) {
      let currentDomainObjectArray = this.filters.filter(
        (site) => site.siteDomain === currentValue
      );
      let currentDomainObject = currentDomainObjectArray[0];
      let userRole = currentDomainObject.userRole;
      if (userRole === "Manager" || userRole === "Administrator") {
        this.authorizedUser = true;
      } else {
        this.authorizedUser = false;
      }
      console.log(
        "current user has access to selected domain?",
        this.authorizedUser
      );
    },
  },
};
</script>
<style scoped>


.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
}

.wpc65 {
  max-width: 65%;
}
.pt-15 {
  padding-top: 15px;
}

.bell-icon {
  height: auto;
  margin: 13px;
  padding: 13px;
  position: relative;
  background: url(../../../assets/images/SVG/bell-icon.svg) no-repeat !important;
}

/* .full-w {
  width: 100%;
} */
.overlay-spinner {
  background-color: rgba(0, 0, 0, 0.5);
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}
/* //////////////////////
    Spinner
////////////////////// */
.spin {
  position: relative;
  margin-top: 15px;
  margin-right: 10px;
}
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f6f6f6;
}
.spinner .path {
  stroke: #b8b8b8;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
