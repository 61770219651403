<template>
  <div class="width-full mt-20">
    <div v-if="selectedDomain != null">
      <div v-if="siteList != null">
        <article v-for="(value, key) in siteList" v-bind:key="key">
          <main>
            <details>
              <summary class="gray-background">
                <h5 aria-hidden="true" class="pl-10 regular">
                  {{ value.title }}
                </h5>
              </summary>
              <div
                class="flex align-center flex-col pb-30 pt-30"
                v-if="confirmDelete[key]"
              >
                <h3 class="font-iop-blue mlr-auto">
                  Are you sure you want to remove this QR Code?
                </h3>
                <p class="text-center">{{ value.url }}</p>
                <div class="flex justify-center mt-30">
                  <button
                    class="blue-200 radius-20 w100 pointer mr-10"
                    @click="cancel(key)"
                  >
                    <div
                      class="
                        font-iop-drk-blue
                        semibold
                        btn-text
                        source-sans-pro
                      "
                    >
                      Cancel
                    </div>
                  </button>
                  <button
                    class="iop-drk-blue-100 radius-20 w300 pointer mr-20"
                    id="delete"
                    @click="archiveQRCode(key, value.url)"
                  >
                    <div class="font-white btn-text">
                      Yes, remove this QR Code
                    </div>
                  </button>
                </div>
              </div>
              <div class="p-30" v-else>
                <div class="bold font-red text-center" v-if="editError[key]">
                  <p>{{ editErrorMessage }}</p>
                </div>
                <div
                  class="bold font-iop-blue text-center mb-30"
                  v-else-if="editSuccess[key] && !editError[key]"
                ></div>

                <div class="flex">
                  <div class="basis-20 flex align-center justify-center">
                    <img
                      v-bind:src="'data:image/jpeg;base64,' + value.image"
                      class="qr-image"
                    />
                  </div>
                  <div class="faq__description">
                    <div class="faq__content flex grow">
                      <div class="pr-30 width-full">
                        <p class="small font-iop-blue m-0">
                          Created
                          {{ convertUTCDateToLocalDate(value.createdDate) }}
                        </p>
                        <div class="">
                          <h4 aria-hidden="true" class="pl-0 bold">
                            {{ value.title }}
                          </h4>
                        </div>
                        <div class="">
                          <p>
                            {{ value.description }}
                          </p>
                        </div>
                        <div class="flex">
                          <div class="max-w30 pt-5 pr-10">
                            <img
                              src="../../../assets/images/SVG/chain_link-blue-icon.svg"
                              style="height: 16px; width: 16px"
                            />
                          </div>
                          <a
                            :href="value.url"
                            target="_blank"
                            class="align-self-start font-iop-blue qr-url"
                            style="color: #004393"
                            >{{ value.url }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-right align-end">
                    <div class="pt-50">
                      <button
                        class="
                          white
                          border
                          radius-20
                          w200
                          pointer
                          mb-20
                          testing-code
                        "
                        id="delete"
                        @click="deleteConfirm(key)"
                      >
                        <div class="icon">
                          <img
                            src="../../../assets/images/SVG/remove-link-icon.svg"
                            style="height: 16px"
                            alt=""
                          />
                        </div>
                        <div
                          class="
                            font-iop-drk-blue
                            semibold
                            btn-text
                            source-sans-pro
                          "
                        >
                          Remove
                        </div>
                      </button>
                      <button
                        class="blue-200 radius-20 w200 pointer testing-code"
                        @click="downloadQrCode(value.image)"
                      >
                        <div class="icon">
                          <img
                            src="../../../assets/images/SVG/download-cloud-icon.svg"
                            style="height: 16px"
                            alt=""
                          />
                        </div>
                        <div
                          class="
                            font-iop-drk-blue
                            semibold
                            btn-text
                            source-sans-pro
                          "
                        >
                          Download QR
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </details>
          </main>
        </article>
      </div>
      <div class="site-list-empty" v-else>
        <p>Your QRCode list is empty.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { AuthConfig } from "../../../auth/auth.config.js";
export default {
  props: ["selectedDomain", "fileredSites", "authorizedUser"],
  data: function () {
    return {
      editSuccess: [],
      editSuccessMessage: "",
      editError: [],
      editErrorMessage: "",
      siteList: [],
      confirmDelete: [],
    };
  },
  created() {
    this.getURList(this.selectedDomain);
  },
  methods: {
    convertUTCDateToLocalDate(UTCDateString) {
      var convertdLocalTime = new Date(UTCDateString);
      var formattedDate =
        convertdLocalTime.getMonth() +
        1 +
        "/" +
        convertdLocalTime.getDate() +
        "/" +
        convertdLocalTime.getFullYear() +
        " ";
      /*   ("00" + convertdLocalTime.getHours()).slice(-2) +
        ":" +
        ("00" + convertdLocalTime.getMinutes()).slice(-2) +
        ":" +
        ("00" + convertdLocalTime.getSeconds()).slice(-2); */
      return formattedDate;
    },
    sortQrData: async function (urlList) {
      //console.log("urlList", urlList);
      if (urlList !== "" && urlList !== "undefined" && urlList !== null) {
        var data = urlList.path;
        let imageArray = [];
        if (data.length > 0) {
          //for each image in list get the base64 decoded values and append to an image array
          data.forEach((o, i) => (o.id = i + 1));
          // get base64 encoded value for active QR's only
          let filteredResult = data.filter((a) => a.status == true);
          if (filteredResult.length > 0) {
            let responseArray = [];
            await Promise.all(
              filteredResult.map(async (element) => {
                imageArray = await this.download(
                  this.selectedDomain,
                  element.url,
                  element.location
                );
                responseArray.push({
                  image: imageArray,
                  url: element.url,
                  id: element.id,
                });
              })
            );
            // construct a result array based on id of data and response array
            const result = filteredResult.map((d) => ({
              ...d,
              ...responseArray.find((i) => i.id === d.id),
            }));
            //console.log("Result Array", result);
            this.siteList = result.sort((a, b) =>
              a.title.localeCompare(b.title)
            );
          } else {
            this.siteList = null;
          }
        } else {
          //console.log("Else");
          this.siteList = null;
        }
      } else {
        this.siteList = null;
        console.log("List is empty");
      }
    },
    getURList: async function (selectedDomain) {
      var responseData = await this.getQrCodeData(selectedDomain);
      if (responseData.status === 200) {
        //console.log("Response Data", responseData.data);
        this.sortQrData(responseData.data);
      } else {
        responseData = "";
        this.getQrCodeData(responseData);
      }
    },
    getQrCodeData: async (selectedDropDown) => {
      let idToken = sessionStorage.getItem("userIDtoken");
      let tokenExpired = sessionStorage.getItem("tokenExpiresIn");
      let apiPath = AuthConfig.getCurrentAuthConfig().apiGateWay;
      // let apiPath ="https://p1911op852.execute-api.us-east-1.amazonaws.com/qrcode-invoke-stg";
      if (Date.now() >= tokenExpired * 1000) {
        let ohidURL = sessionStorage.getItem("ohidUrl");
        window.open(ohidURL + "/pkmslogout", "_self").focus();
      } else {
        let config = {
          headers: {
            Authorization: idToken,
          },
        };
        try {
          const response = await axios.get(
            apiPath + "/devportal-api/" + selectedDropDown,
            config
          );
          if (response.status == 200) {
            // for debugging only
            //console.log("Get list of QRCodes", response.data);
            return response;
          }
          return response;
        } catch (err) {
          if (err.response) {
            console.log("Something went wrong", err.response.status);
            return err.response.status;
          } else {
            console.log("CORS ERROR", err);
            return err;
          }
        }
      }
    },
    deleteConfirm: async function (key) {
      Vue.set(this.confirmDelete, key, 1);
    },
    cancel: async function (key) {
      Vue.set(this.confirmDelete, key, 0);
    },
    archiveQRCode: async function (key, selectedDomain) {
      this.beforeConfirm = true;
      this.confirmDelete = false;
      var delLongURL = await this.archiveQrUrl(key, selectedDomain);
      if (delLongURL.status === 201) {
        //console.log("Delete Response Status", delLongURL.data);
        Vue.set(this.editError, key, 0);
        Vue.set(this.editSuccess, key, 1);
        // this.editSuccessMessage = delLongURL.data.message;
        this.editSuccessMessage =
          "Success! This URL has been archived. Please click 'Okay' to Refresh the list.";
        this.reloadList(selectedDomain);
      } else if (delLongURL.status === 400) {
        //console.log("Cannot Archive-400 Error", delLongURL.data.error);
        //console.log("key", key);
        Vue.set(this.editSuccess, key, 0);
        Vue.set(this.editError, key, 1);
        this.editErrorMessage = delLongURL.data.error;
        this.confirmDelete = [];
      } else {
        console.log("Something went wrong");
        Vue.set(this.editError, key, 1);
        this.editErrorMessage = "Something went wrong";
        this.confirmDelete = [];
      }
    },
    archiveQrUrl: async (key, url) => {
      let encodeURL = new URL(url);
      let domain = encodeURL.hostname;
      let idToken = sessionStorage.getItem("userIDtoken");
      let tokenExpired = sessionStorage.getItem("tokenExpiresIn");
      let apiPath = AuthConfig.getCurrentAuthConfig().apiGateWay;
      //let apiPath ="https://p1911op852.execute-api.us-east-1.amazonaws.com/qrcode-invoke-stg";
      if (Date.now() >= tokenExpired * 1000) {
        let ohidURL = sessionStorage.getItem("ohidUrl");
        window.open(ohidURL + "/pkmslogout", "_self").focus();
      } else {
        let config = {
          headers: {
            Authorization: idToken,
          },
        };
        try {
          const delResponse = await axios.put(
            apiPath + "/devportal-api/" + domain,
            {
              url: url,
            }, config
          );
          if (delResponse.status == 201) {
            return delResponse;
          }
          return delResponse;
        } catch (err) {
          if (err.delResponse) {
            return err.delResponse.status;
          } else if (err.response) {
            return err.response;
          } else {
            console.log("CORS error", err);
            return err;
          }
        }
      }
    },
    reloadList: async function (selectedDomain) {
      let encodeURL = new URL(selectedDomain);
      let domain = encodeURL.hostname;
      this.editErrorMessage = "";
      this.editSuccessMessage = "";
      this.confirmDelete = [];
      this.getURList(domain);
    },
    downloadQrCode: async function (image) {
      var a = document.createElement("a");
      a.href = "data:image/png;base64," + image;
      a.download = "Image.png";
      a.click();
    },
    download: async function (domain, url, location) {
      //console.log("selectedDomain, url", domain, url, location);
      let idToken = sessionStorage.getItem("userIDtoken");
      let tokenExpired = sessionStorage.getItem("tokenExpiresIn");
      let apiPath = AuthConfig.getCurrentAuthConfig().apiGateWay;
      //let apiPath ="https://p1911op852.execute-api.us-east-1.amazonaws.com/qrcode-invoke-stg";
      if (Date.now() >= tokenExpired * 1000) {
        let ohidURL = sessionStorage.getItem("ohidUrl");
        window.open(ohidURL + "/pkmslogout", "_self").focus();
      } else {
        try {
          const response = await axios.get(
            apiPath + "/devportal-api/download/" + domain,
            {
              headers: {
                Authorization: idToken,
              },
              params: {
                url: url,
                location: location,
              },
            }
          );
          if (response.status == 200) {
            // for debugging only
            //console.log("download QR", response);
            return response.data;
          }
          return response;
        } catch (err) {
          if (err.response) {
            console.log("Something went wrong", err.response.status);
            return err.response.status;
          } else {
            console.log("CORS ERROR", err);
            return err;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
button#delete[disabled],
button#delete:disabled {
  pointer-events: none;
}
details > summary::marker {
  content: "";
}

details > summary::after {
  content: "";
  position: absolute;
  top: 29px;
  right: 20px;
  height: 14px;
  width: 14px;
  background: url(../../../assets/images/SVG/chevron-down.svg) no-repeat;
  cursor: pointer;
}

summary:hover {
  background: var(--blue-200);
}

details {
  max-width: none;
  width: auto;
}

details[open] summary {
  background: var(--blue-200);
}

details[open] {
  background: #fff;
  margin: 20px auto;
}

.ds-text-field__input {
  border-bottom: 1px solid var(--iop-drk-blue-800);
}

.icon {
  padding-left: 0px;
  margin-right: 5px;
}
.qr-image {
  height: 120px !important;
  width: 120px !important;
}
.qr-url {
  word-break: break-word !important;
}
.faq__description {
  width: 55%;
  min-width: 350px;
}
</style>
